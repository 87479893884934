import { Action, Reducer } from 'redux';

import { OrderDishStatus, OrderStatus, PaymentMethod, OrderServices } from 'constants/enums';
import { isFullPageCount } from 'helpers/helperFunctions';
import { OrdersAction } from './actions';

export interface OrderData {
    id: string;
    orderNumber: number;
    createDate: string;
    status: OrderStatus;
}

export interface OrderDetails {
    id: string;
    orderNumber: number;
    createDate: number;
    orderItems: OrderItem[];
    status: OrderStatus;
    isPaid: boolean;
    totalAmount: number;
    firstName: string;
    lastName: string;
    addressLine: string;
    city: string;
    zip: string;
    phoneNumber: string;
    email: string;
    comment: string;
    deliveryTime: number;
    service: OrderServices;
    paymentMethod: PaymentMethod;
    qrCodeName: string;
    restaurantId?: string;
    responsibleUserId?: string;
    responsibleUserName?: string;
}

export interface OrderItem {
    price: number;
    name: string;
    id: string;
    amount: number;
    shortDescription: string;
    orderId?: string;
    isReady?: boolean;
    orderNumber?: number;
    dishes: OrderItem[];
    comment?: string;
    service?: OrderServices;
    ingredients?: any[];
    status: OrderDishStatus;
    categoryId: string;
    categoryName: string;
    dailyMenuId: string;
    dailyMenuName?: string;
    dailyMenuPrice?: number;
    qrCodeName?: string;
    modifiedByManagement?: number;
    uniqueId?: string;
    allergens?: any;
    isVegan?: boolean;
    isVegeterian?: boolean;
    isSpicy?: boolean;
    isHalal?: boolean;
    modified?: boolean;
    modifiedIngredients?: ModifiedIngredient[];
    isPaid?: boolean;
}

export interface OrderDetailsItem extends OrderItem {
    //dishes: OrderItem[];
}

export interface ModifiedIngredient {
    id: string;
    isAdditionChecked?: boolean;
    isRemovalChecked?: boolean;
}

export interface OverdudedOrder {
    id: string;
    orderNumber: number;
    orderCreationDate: number;
    status: OrderStatus;
}

export interface OrderReadyDishes {
    [key: string]: string[];
}

//STATE
export interface OrdersState {
    orders: OrderDetails[];
    positionOrders: OrderItem[];
    orderDetailsId: string;
    orderDetails: OrderDetails;
    searchString: string;
    newOrders: number;
    count: number;
    positionOrdersLoaded: boolean;
    overdudedOrders: OverdudedOrder[];
    showSearch: boolean;
    needToUpdate: boolean;
    removedModifiedOrders: string[];
    initialOrderItems: OrderItem[];
    selectedDishId: string;
    selectedDailyMenuId?: string;
    itemsToAdd: OrderItem[];
}

const initialState: OrdersState = {
    orders: [],
    positionOrders: [],
    count: 0,
    newOrders: 0,
    orderDetailsId: '',
    orderDetails: {
        id: '',
        orderNumber: 0,
        createDate: 0,
        orderItems: [],
        status: OrderStatus.Non,
        isPaid: false,
        totalAmount: 0,
        firstName: '',
        lastName: '',
        addressLine: '',
        city: '',
        zip: '',
        phoneNumber: '',
        email: '',
        comment: '',
        deliveryTime: 0,
        service: OrderServices.OrderInRestaurant,
        paymentMethod: PaymentMethod.None,
        qrCodeName: '',
    },
    searchString: '',
    positionOrdersLoaded: false,
    overdudedOrders: [],
    showSearch: false,
    needToUpdate: false,
    removedModifiedOrders: [],
    initialOrderItems: [],
    selectedDishId: '',
    selectedDailyMenuId: undefined,
    itemsToAdd: [],
};

export const reducer: Reducer<OrdersState> = (
    state: OrdersState | undefined = initialState,
    incomingAction: Action
): OrdersState => {
    const action = incomingAction as OrdersAction;
    switch (action.type) {
        case 'GET_ORDERS_LIST_SUCCESS': {
            return {
                ...state,
                orders: action.list,
                count: action.count,
                orderDetailsId: action.overduded
                    ? action.list[0]?.id
                    : !state.orderDetailsId
                    ? action.list[0]?.id
                    : state.orderDetailsId,
            };
        }
        case 'UPDATE_ORDERS_LIST_SUCCESS': {
            let updatedOrders = [...state.orders];
            const previousOrdersIds = updatedOrders.map((x) => x.id);
            const newOrders = action.list.filter((x) => !previousOrdersIds.includes(x.id));
            if (newOrders.length === updatedOrders.length) {
                updatedOrders = [...newOrders];
            } else {
                updatedOrders.splice(updatedOrders.length - newOrders.length - 1, newOrders.length);
                updatedOrders.splice(0, 0, ...newOrders);
            }
            return {
                ...state,
                orders: action.list,
                count: action.count,
                orderDetailsId: !!!state.orderDetailsId ? action.list[0]?.id : state.orderDetailsId,
            };
        }
        case 'SET_ORDER_DETAILS_ID': {
            return {
                ...state,
                orderDetailsId: action.id,
            };
        }
        case 'CHANGE_ORDER_ITEM_STATUS_SUCCESS': {
            let orderToUpdate = { ...state.orders.find((x) => x.id === action.order.id)! };
            orderToUpdate.status = action.order.status;
            let itemToUpdate;
            if (action.dailyMenuId) {
                itemToUpdate = orderToUpdate.orderItems
                    .find((x) => x.id === action.dailyMenuId)!
                    .dishes.find((y) => y.id === action.itemId)!;
            } else {
                itemToUpdate = orderToUpdate.orderItems.find((x) => x.id === action.itemId)!;
            }

            itemToUpdate.status =
                itemToUpdate.status === OrderDishStatus.None
                    ? OrderDishStatus.Ready
                    : OrderDishStatus.None;
            return {
                ...state,
                orders: state.orders.map((x) => (x.id === orderToUpdate.id ? orderToUpdate : x)),
            };
        }
        case 'UPDATE_ORDER_STATUS': {
            return {
                ...state,
                orders: state.orders.map((x) =>
                    x.id !== action.id ? x : { ...x, status: action.status }
                ),
            };
        }
        case 'SET_SEARCH_STRING': {
            return {
                ...state,
                searchString: action.data,
            };
        }
        case 'GET_NEW_ORDERS_SUCCESS': {
            return {
                ...state,
                newOrders: action.newOrders,
            };
        }
        case 'GET_POSITION_ORDERS_SUCCESS': {
            return {
                ...state,
                positionOrders: action.data,
                positionOrdersLoaded: true,
            };
        }
        case 'GET_POSITION_ORDERS_ERROR': {
            return {
                ...state,
                positionOrdersLoaded: true,
            };
        }
        case 'CHANGE_POSITION_ORDER_STATUS_ERROR': {
            const indexOfItem = state?.positionOrders.findIndex(
                (each) => each.orderId === action.orderId
            );
            if (indexOfItem > -1) {
                const newPositionOrders: any = [...state?.positionOrders];
                newPositionOrders[indexOfItem].status = action.previousStatus;
                return {
                    ...state,
                    positionOrders: newPositionOrders,
                };
            }
            return {
                ...state,
            };
        }
        case 'CREATE_EMPTY_ORDER_START': {
            return {
                ...state,
            };
        }
        case 'CREATE_EMPTY_ORDER_SUCCESS': {
            let newOrders = [...state.orders];
            if (isFullPageCount(newOrders.length)) {
                newOrders.splice(newOrders.length - 1, 1);
                newOrders.splice(0, 0, action.data);
            } else {
                newOrders.push(action.data);
            }

            return {
                ...state,
                orders: [...newOrders],
                orderDetailsId: action.data.id,
            };
        }
        case 'UPDATE_ORDER_SUCCESS': {
            return {
                ...state,
                orders: [...state.orders.map((x) => (x.id === action.order.id ? action.order : x))],
                orderDetails: action.order,
                initialOrderItems: [...action.order.orderItems],
                itemsToAdd: [],
            };
        }
        case 'GET_ORDER_DETAILS_SUCCESS': {
            return {
                ...state,
                orders: [...state.orders.map((x) => (x.id === action.order.id ? action.order : x))],
                orderDetails: action.order,
                initialOrderItems: [...action.order.orderItems],
                needToUpdate: false,
            };
        }
        case 'SET_ORDER_DETAILS': {
            return {
                ...state,
                orderDetails: action.order,
                initialOrderItems: [...action.order.orderItems],
            };
        }
        case 'RESET_ORDER_DETAILS': {
            return {
                ...state,
                orderDetails: state.orders.find((x) => x.id === state.orderDetailsId)!,
                removedModifiedOrders: [],
            };
        }
        case 'SET_OVERDUDED_ORDERS': {
            return {
                ...state,
                overdudedOrders: action.orders,
            };
        }
        case 'SET_SHOW_SEARCH': {
            return {
                ...state,
                showSearch: action.toggle ? !state.showSearch : action.showSearch,
            };
        }
        case 'UPDATE_READY_ORDERS_DISHES': {
            return {
                ...state,
                orders: state.orders.map((o) => ({
                    ...o,
                    orderItems: o.orderItems.map((i) =>
                        action.data[o.id]?.includes(i.id)
                            ? { ...i, status: OrderDishStatus.Ready }
                            : i
                    ),
                    status:
                        o.orderItems.length === 0 ||
                        o.orderItems.filter((x) => x.status !== OrderDishStatus.Ready)?.length >
                            0 ||
                        o.status === OrderStatus.Closed
                            ? o.status
                            : OrderStatus.Ready,
                })),
                orderDetails: {
                    ...state.orderDetails,
                    orderItems: state.orderDetails.orderItems.map((i) =>
                        action.data[state.orderDetails.id]?.includes(i.id)
                            ? { ...i, status: OrderDishStatus.Ready }
                            : i
                    ),
                },
                needToUpdate: !!action.data[state.orderDetails.id],
            };
        }
        case 'SET_SELECTED_DISH': {
            return {
                ...state,
                selectedDishId: action.data,
                selectedDailyMenuId: action.selectedDailyMenuId,
            };
        }
        case 'ADD_ITEM_TO_ORDER': {
            const { item, amount } = action;
            const orderDetails = state?.orderDetails;
            // Add +1
            if (amount === 1) {
                if (item.uniqueId) {
                    const targetItem = orderDetails?.orderItems.find(
                        (each) => each.uniqueId === item.uniqueId
                    );
                    if (targetItem) {
                        if (JSON.stringify(targetItem) === JSON.stringify(item))
                            targetItem.amount++;
                        else {
                            orderDetails?.orderItems.push({ ...item, uniqueId: '' });
                        }
                    }
                } else {
                    const targetItem = orderDetails?.orderItems.find((each) => each.id === item.id);
                    if (targetItem) {
                        if (JSON.stringify(targetItem) === JSON.stringify(item))
                            targetItem.amount++;
                        else {
                            orderDetails?.orderItems.push({ ...item, uniqueId: '' });
                        }
                        targetItem.amount++;
                    } else {
                        orderDetails?.orderItems.push(item);
                    }
                }
            }
            // Remove it totally
            else if (amount === 0 && orderDetails) {
                if (item.uniqueId) {
                    orderDetails.orderItems = orderDetails?.orderItems.filter(
                        (each) => each.uniqueId !== item.uniqueId
                    );
                } else {
                    orderDetails.orderItems = orderDetails?.orderItems.filter(
                        (each) => each.id !== item.id
                    );
                }
            }
            // Subtract -1
            else {
                let indexOfItem = orderDetails?.orderItems.findIndex((each) =>
                    item.uniqueId ? each.uniqueId === item.uniqueId : each.id === item.id
                );
                if (indexOfItem > -1) {
                    const targetItem = orderDetails?.orderItems[indexOfItem];
                    if (targetItem.amount > 1) {
                        targetItem.amount--;
                    } else {
                        orderDetails?.orderItems.splice(indexOfItem, 1);
                    }
                }
            }
            if (action.action) {
                action.action();
            }

            return {
                ...state,
                orderDetails,
            };
        }
        case 'ORDER_SET_RESPONSIBLE_USER_SUCCESS': {
            return {
                ...state,
                orders: [...state.orders.map((x) => (x.id === action.order.id ? action.order : x))],
                orderDetails: action.order,
                initialOrderItems: [...action.order.orderItems],
                needToUpdate: false,
            };
        }
        case 'ORDER_ADD_ITEM_TO_ADD': {
            if (action.item !== null) {
                let updatedItems = [...state.itemsToAdd];
                let existsItem = action.item.uniqueId
                    ? state.itemsToAdd.find((item) => item.uniqueId === action.item!.uniqueId)
                    : state.itemsToAdd.find((item) => item.id === action.item!.id);
                let existsDailyMenu = state.itemsToAdd.find(
                    (item) => item.id === action.item!.dailyMenuId
                );
                if (existsItem) {
                    if (action.changeIngredients) {
                        existsItem.modifiedIngredients = action.item.modifiedIngredients;
                    } else {
                        if (action.decrease) {
                            existsItem.amount--;
                        } else {
                            existsItem.amount++;
                        }
                        if (existsItem.amount <= 0) {
                            updatedItems = updatedItems.filter(
                                (item) => item.id !== existsItem!.id
                            );
                        } else {
                            updatedItems = updatedItems.map((item) =>
                                item.id === existsItem!.id ? existsItem! : item
                            );
                        }
                    }
                } else if (existsDailyMenu) {
                    let existsDishIndex = existsDailyMenu.dishes.findIndex(
                        (dish) => dish.id === action.item?.id
                    );
                    if (existsDishIndex > -1) {
                        existsDailyMenu.dishes[existsDishIndex].modifiedIngredients =
                            action.item.modifiedIngredients;
                        updatedItems = updatedItems.map((item) =>
                            item.id === existsDailyMenu!.id ? existsDailyMenu! : item
                        );
                    }
                } else {
                    if (action.decrease) {
                        existsItem = state.orderDetails.orderItems.find(
                            (x) => x.uniqueId === action.item?.uniqueId
                        );
                        if (existsItem) {
                            updatedItems.push({ ...action.item, amount: -1 });
                        }
                    } else {
                        updatedItems.push(action.item);
                    }
                }
                return {
                    ...state,
                    itemsToAdd: [...updatedItems],
                };
            } else {
                return {
                    ...state,
                    itemsToAdd: [],
                };
            }
        }
        default:
            return state;
    }
};
