import React, { useState, useEffect, useRef } from 'react';

import i18n from 'i18n-js';
import { Button, IconButton, TextField } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import agent from 'api/agent';
import { OrderUpdateModel } from 'api/models';
import { images } from 'assets';
import { DishData, MenuDetailsData, SectionData } from 'store/menuDetailsStore/reducer';
import { MenusTable } from './components';
import { SearchResultTable } from './components';
import { AddNewMenu } from './components';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store';
import { Positions, MenuType } from 'constants/enums';
import globalStore from 'store/globalStore';
import restaurantStore from 'store/restaurantDetailsStore';
import { default as ingredientStore } from 'store/ingredientsStore';
import { useStyles } from './styles';
import { useAppSelector } from 'index';

export interface MenuSections {
    menuId: string;
    sections: SectionData[];
}

export const MenusPage = (props: {
    onClose?: () => any;
    onHide?: () => any;
    isModal?: boolean;
    saveChanges: (model: OrderUpdateModel | null) => void;
}) => {
    //const order = useSelector((state: ApplicationState) => state.orders.orderDetails);
    const initialOrderItems = useSelector(
        (state: ApplicationState) => state.orders.initialOrderItems
    );
    const language = useAppSelector((state) => state.global.language);
    const itemsToAdd = useAppSelector((state) => state.orders.itemsToAdd);
    const menus = useAppSelector((state) => state.menus.menus);

    const dispatch = useDispatch();
    const classes = useStyles();
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [showSearchResult, setShowSearchResult] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [menusDetails, setMenusDetails] = useState<MenuDetailsData[]>([]);
    const [searchResult, setSearchResult] = useState<MenuDetailsData[]>([]);
    const [sectionsByMenu, setSectionByMenu] = useState<MenuSections[]>([]);
    const user = useSelector((state: ApplicationState) => state.user);
    const taxes = useAppSelector((state) => state.restaurantDetails.taxes);
    const currentRestaurant = user.restaurants?.find((x) => x.id === user.currentRestaurantId);
    const [menuName, setMenuName] = useState('');
    const [menuId, setMenuId] = useState('');
    const [showMenuDetails, setShowMenuDetails] = useState(false);
    const initialOrderItemAmount = useRef(0);

    useEffect(() => {
        initialOrderItemAmount.current = initialOrderItems.reduce(
            (accumulator, currentValue) => accumulator + currentValue.amount,
            0
        );
        getMenuesDetails();
        dispatch(ingredientStore.actionCreators.getAllergies(language));
        if (!!!taxes.id) {
            dispatch(restaurantStore.actionCreators.getTaxes());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    function handleDetails(id: string) {
        setMenuId(id);
        setShowMenuDetails(true);
    }

    function closeSearch() {
        setShowSearchBar(false);
        setShowSearchResult(false);
        setSearchString('');
        getMenuesDetails();
    }

    function resetSearch() {
        setSearchString('');
        setSearchResult([]);
        setShowSearchResult(false);
    }

    function filterSectionsByMenus() {
        let sections: MenuSections[] = [];
        menusDetails.forEach((menu) => {
            sections.push({ menuId: menu.id, sections: menu.sections });
        });
        setSectionByMenu(sections);
    }

    function updateSearch(updatedDish: DishData, isDeleted: boolean = false) {
        let menu = searchResult.find(
            (menu) =>
                menu.name.toLowerCase() ||
                menu.sections.some((x) => x.dishes.some((y) => y.id === updatedDish.id))
        );
        if (menu) {
            let section = menu.sections.find((section) =>
                section.dishes.some((dish) => dish.id === updatedDish.id)
            );
            if (section) {
                if (isDeleted) {
                    const targetIndex = section.dishes.findIndex((x) => x.id === updatedDish.id);
                    section.dishes.splice(targetIndex, 1);
                } else {
                    section.dishes = section.dishes.map((dish) =>
                        dish.id === updatedDish.id && !isDeleted ? updatedDish : dish
                    );
                }

                setSearchResult(searchResult.map((x) => (x.id !== menu!.id ? x : menu!)));
                getMenuesDetails();
            }
        }
    }

    function getSearchResult() {
        setSearchResult([]);
        const searchValue = new RegExp(searchString, 'gi');
        let menusResult: MenuDetailsData[] = [];
        menusDetails.forEach((menu) => {
            if (currentRestaurant?.currentPosition !== Positions.MANAGER && !menu.isEnabled) {
                return;
            }
            let sectionsResult: SectionData[] = [];
            let targetDishes: string[] = [];
            if (!!menu.name.match(searchValue) && menu.sections?.length === 0) {
                menusResult.push({ ...menu, sections: [] });
            } else if (menu.sections?.length > 0) {
                menu.sections.forEach((section) => {
                    if (section.dishes.length > 0) {
                        section.dishes.forEach((dish) => {
                            if (
                                dish.name.match(searchValue) ||
                                dish.description.match(searchValue) ||
                                dish.shortDescription.match(searchValue)
                            ) {
                                targetDishes.push(dish.id);
                            }
                        });
                    }
                    if (targetDishes.length > 0) {
                        sectionsResult.push({
                            ...section,
                            dishes: section.dishes.filter((x) => targetDishes.includes(x.id)),
                        });
                    }
                });

                if (sectionsResult.length > 0) {
                    menusResult.push({ ...menu, sections: sectionsResult });
                } else if (!!menu.name.match(searchValue)) {
                    menusResult.push({ ...menu });
                }
            }
        });
        setSearchResult(menusResult);
        sectionsByMenu.length === 0 && filterSectionsByMenus();
    }

    function getMenuesDetails() {
        dispatch(globalStore.actionCreators.showSpiner());
        agent.Menus.GetMenusDetails()
            .then((response) => setMenusDetails(response))
            .catch((e) => dispatch(globalStore.actionCreators.showToaster('error', e)))
            .finally(() => dispatch(globalStore.actionCreators.hideSpiner()));
    }

    function handleSearchByEnter(e: any) {
        if (e.key === 'Enter') {
            getSearchResult();
            setShowSearchResult(true);
        }
    }

    /* function modifiedItemsAmount() {
        const length = order.orderItems.reduce(
            (accumulator, currentValue) => accumulator + currentValue.amount,
            0
        );
        return length - initialOrderItemAmount.current;
    } */

    return (
        <>
            <div className={`${classes.header} ${classes.flexRowSpaceBetween}`}>
                <div className={`${classes.actionsContainer} ${classes.flexRowSpaceBetween}`}>
                    {showSearchBar && (
                        <img src={images.icons.backArrow} onClick={closeSearch} alt="back-arrow" />
                    )}
                    {!showSearchBar && !showSearchResult && !!menuName ? (
                        <>
                            <IconButton
                                aria-label="delete"
                                size="small"
                                className={classes.iconButton}
                                onClick={() => {
                                    setShowMenuDetails(false);
                                    setMenuName('');
                                }}
                            >
                                <ArrowBackIcon fontSize="inherit" />
                            </IconButton>
                            <span>{menuName}</span>
                        </>
                    ) : !showSearchBar ? (
                        <span>Menu</span>
                    ) : (
                        <></>
                    )}
                    {showSearchBar ? (
                        <img
                            src={
                                searchString.length > 0 ? images.icons.lensBlue : images.icons.lens
                            }
                            className={classes.lensIcon}
                            onClick={() => searchString.length > 0 && getSearchResult()}
                            alt="search-icon"
                        />
                    ) : (
                        <img
                            src={images.icons.lens}
                            className={classes.lensIcon}
                            onClick={() => setShowSearchBar(true)}
                            alt="lens-icon"
                        />
                    )}
                    {showSearchBar && (
                        <TextField
                            id="search-input"
                            placeholder={i18n.t('form.searchWord')}
                            onChange={(e) => setSearchString(e.target.value)}
                            value={searchString}
                            onKeyDown={(e) => handleSearchByEnter(e)}
                            className={searchString.trim().length > 0 ? classes.searchInput : ''}
                        />
                    )}
                </div>
                {showSearchBar ? (
                    <Button variant="outlined" color="primary" onClick={resetSearch}>
                        {i18n.t('form.resetSearch')}
                    </Button>
                ) : props.isModal ? (
                    <div>
                        <Button variant="text" color="primary" onClick={props.onClose}>
                            {i18n.t('button.close')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!itemsToAdd.length}
                            onClick={() => props.saveChanges(null)}
                        >
                            + {i18n.t('button.addToOrder')}
                            {!!itemsToAdd.length &&
                                ` (${itemsToAdd.reduce(
                                    (accum: number, current) => accum + current.amount,
                                    0
                                )})`}
                            {/* {modifiedItemsAmount() !== 0 && ` (${modifiedItemsAmount()})`} */}
                        </Button>
                    </div>
                ) : (
                    currentRestaurant?.currentPosition === Positions.MANAGER && <AddNewMenu />
                )}
            </div>
            {showSearchResult ? (
                <SearchResultTable
                    menus={searchResult}
                    sections={sectionsByMenu}
                    recalculate={updateSearch}
                    searchString={searchString}
                    isModal={props.isModal}
                />
            ) : (
                <div style={{ overflowY: 'auto' }}>
                    <MenusTable
                        isModal={props.isModal}
                        close={props.onClose}
                        setMenuName={setMenuName}
                        menuId={menuId}
                        handleDetails={handleDetails}
                        showMenuDetails={showMenuDetails}
                        setShowMenuDetails={() => setShowMenuDetails(false)}
                        menus={menus.filter((x) => x.type !== MenuType.Special)}
                    />
                    {menus.find((x) => x.type === MenuType.Special) && !showMenuDetails && (
                        <MenusTable
                            isModal={props.isModal}
                            close={props.onClose}
                            setMenuName={setMenuName}
                            menuId={menuId}
                            handleDetails={handleDetails}
                            showMenuDetails={showMenuDetails}
                            setShowMenuDetails={() => setShowMenuDetails(false)}
                            menus={menus.filter((x) => x.type === MenuType.Special)}
                            separatorText="SPECIAL MENU"
                        />
                    )}
                </div>
            )}
        </>
    );
};
