import { makeStyles, createStyles, alpha, Theme } from '@material-ui/core';
import commonStyles from 'config/commonStyles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        dialog: {
            '& .MuiPaper-root.MuiDialog-paper': {
                marginTop: 10,
                marginBottom: 10,
                maxHeight: '90%',
            },
            /* '& .MuiDialogTitle-root': {
                paddingBottom: 0,
            }, */
            '& .MuiDialogContent-root': {
                paddingTop: 24,
            },
            '& .MuiSelect-icon': {
                color: theme.palette.secondary.dark,
            },
        },
        flexRowSpaceBetween: {
            ...commonStyles.flexRowSpaceBetween,
        },
        selectService: {
            marginBottom: 28,
            marginTop: 0,
            '& .MuiSelect-root': {
                ...commonStyles.flexRowSpaceBetween,
            },
        },
        selectServiceSelected: {
            '& .MuiSelect-root': {
                paddingTop: 10,
            },
            '& .MuiListItemText-root': {
                textAlign: 'start',
            },
        },
        selectServiceContainer: {
            top: '150px !important',
        },
        selectServiceOptions: {
            width: '100%',
        },
        labelTitle: {
            fontSize: 10,
            letterSpacing: '1.5px',
            textTransform: 'uppercase',
            color: alpha(theme.palette.common.black, 0.87),
            position: 'absolute',
            top: -20,
        },
        deliveryTimeText: {
            position: 'absolute',
            top: 20,
            left: 12,
            '& span': {
                fontWeight: 600,
            },
        },
        ordersTable: {
            height: '100%',
            minHeight: '100%',
            //minWidth: 300,
            //width: '30%',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            '& .MuiTypography-root.MuiTypography-h6': {
                fontSize: 16,
                letterSpacing: 0.15,
            },
            '& .MuiToolbar-root.MuiToolbar-regular': {
                padding: '0 16px !important',
                justifyContent: 'space-between',
            },
            '& .MuiSelect-select:focus': {
                backgroundColor: 'transparent',
            },
            '& .MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters': {
                padding: '16px 20px 16px 0 !important',
                justifyContent: 'space-between',
            },
            // '& td': { cursor: 'pointer' },
            '& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:not(.Mui-disabled):before':
                {
                    borderBottom: `1px solid ${theme.palette.primary.main}`,
                },
            '& .MuiFormControl-fullWidth': {
                marginTop: -16,
                width: 200,
            },
            '& .MuiInputLabel-shrink': {
                display: 'none',
            },
            '& .MuiTableContainer-root': {
                height: '100%',
                maxHeight: '100%',
            },
        },
        ordersTableHeader: {
            color: theme.palette.action.disabled,
            '& .MuiTableSortLabel-icon': {
                marginTop: '0px !important',
            },
            '&:not(:first-child)': {
                paddingLeft: 0,
            },
            '&:last-child': {
                paddingBottom: '16px !important',
            },
        },
        lensIcon: {
            marginLeft: 20,
            cursor: 'pointer',
        },
        noPadding: {
            paddingLeft: 0,
        },
        activeColor: {
            backgroundColor: alpha(theme.palette.primary.main, 0.16),
        },
        resetSearchIcon: {
            padding: 9,
            color: theme.palette.primary.main,
            cursor: 'pointer',
            width: 20,
        },
        listContainer: {
            ...commonStyles.flexRowSpaceBetween,
            flexGrow: 1,
            maxHeight: 'calc(100% - 134px)',
            alignItems: 'flex-start',
            position: 'relative',
            overflowY: 'auto',
            overflowX: 'hidden',
            '& .MuiTable-root': {
                height: '100%',
            },
            '& .MuiTableCell-body': {
                paddingTop: 12,
                paddingBottom: 12,
            },
        },
        searchBar: {
            ...commonStyles.flexRowStart,
            margin: '0 !important',
            height: 70,
            minHeight: '70px !important',
        },
        addNewOrderIcon: {
            height: 20,
            width: 20,
            color: theme.palette.primary.main,
        },
        actionsContainer: {
            ...commonStyles.flexRowSpaceBetween,
            '& img': {
                cursor: 'pointer',
            },
            width: 50,
        },
    })
);
