import React from 'react';
import { useDispatch } from 'react-redux';

import {
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    InputAdornment,
    TextField,
} from '@material-ui/core';
import i18n from 'i18n-js';
import * as yup from 'yup';
import { Autocomplete } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';

import { default as ingredientsStore } from 'store/ingredientsStore';

import { Input } from 'components/shared';
import { useStyles } from '../styles';
import { useAppSelector } from 'index';
import { Allergen } from 'store/ingredientsStore/reducer';

interface EditIngredientModalProps {
    isVisible: boolean;
    onClose: () => void;
    defaultValues?: any;
}

const weightTypes = [
    { name: 'gr', id: 1 },
    { name: 'kg', id: 2 },
    { name: 'ml', id: 3 },
    { name: 'st', id: 4 },
];

export default function EditIngredientModal(props: EditIngredientModalProps) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const categories = useAppSelector((state) => state.ingredients.categories);
    const allergens = useAppSelector((state) => state.ingredients.allergies);

    const validationSchema = yup.object().shape({
        name: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Name',
            })
        ),
        category: yup.object().required(
            i18n.t('form.errors.required', {
                name: 'Category',
            })
        ),
        amount: yup
            .number()
            .min(0, 'Min 0')
            .required(
                i18n.t('form.errors.required', {
                    name: 'Amount',
                })
            ),
        price: yup
            .number()
            .min(0, 'Min 0')
            .required(
                i18n.t('form.errors.required', {
                    name: 'Price',
                })
            ),
        unitOfMeasure: yup.object().required(
            i18n.t('form.errors.required', {
                name: 'Unit of Measure',
            })
        ),
    });

    function getSelectedAllergensOptions(selectedAllergens: string[]) {
        const optionsList: Allergen[] = [];
        selectedAllergens.forEach((allergenId) => {
            const option = allergens.find((each) => each.id === allergenId);
            option && optionsList.push(option);
        });
        return optionsList;
    }

    function getSelectedAllergensList(allergens: Allergen[], allergenIds?: string[]) {
        if (allergenIds) {
            return allergenIds.map((allergenId) => {
                return allergens.find((each) => each.id === allergenId)?.name;
            });
        }
        return allergens.map((allergen) => allergen.name + ' / ');
    }

    return (
        <Dialog open={props.isVisible}>
            <DialogTitle>
                {i18n.t(`${props.defaultValues ? 'form.editProduct' : 'form.createNewProduct'}`)}
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={
                        props.defaultValues
                            ? {
                                  ...props.defaultValues,
                                  unitOfMeasure: weightTypes.find(
                                      (each) => each.name === props.defaultValues.unitOfMeasure
                                  ),
                                  category: categories.find(
                                      (each) => each.id === props.defaultValues.categoryId
                                  ),
                                  allergens: getSelectedAllergensOptions(
                                      props.defaultValues.allergens
                                  ),
                              }
                            : {
                                  name: '',
                                  category: '',
                                  amount: 0,
                                  price: 0,
                                  allergens: [],
                                  unitOfMeasure: weightTypes[0],
                              }
                    }
                    validationSchema={validationSchema}
                    validateOnBlur={false}
                    validateOnChange={true}
                    onSubmit={(values) => {
                        dispatch(
                            props.defaultValues
                                ? ingredientsStore.actionCreators.updateIngredient({
                                      name: values.name,
                                      id: props.defaultValues.id,
                                      lang: 'en',
                                      price: values.price,
                                      amount: values.amount,
                                      unitOfMeasure: values.unitOfMeasure.name,
                                      categoryId: values.category.id,
                                      allergens: [
                                          ...values.allergens.map(
                                              (allergen: Allergen) => allergen.id
                                          ),
                                      ],
                                  })
                                : ingredientsStore.actionCreators.createIngredient({
                                      name: values.name,
                                      price: values.price,
                                      amount: values.amount,
                                      unitOfMeasure: values.unitOfMeasure.name,
                                      categoryId: values.category.id,
                                      allergens: [
                                          ...values.allergens.map(
                                              (allergen: Allergen) => allergen.id
                                          ),
                                      ],
                                  })
                        );
                        props.onClose();
                    }}
                >
                    {({ submitForm, values, setFieldValue, errors, initialValues, dirty }) => {
                        return (
                            <form>
                                <Input
                                    error={errors.name}
                                    variant="filled"
                                    fullWidth={true}
                                    name={'name'}
                                    type="text"
                                    label={i18n.t('form.ingredientName')}
                                    placeholder={i18n.t('form.ingredientName')}
                                />
                                <Autocomplete
                                    disableClearable
                                    options={categories}
                                    getOptionLabel={(option) => option.name}
                                    value={values.category}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    onChange={(e: object, value: any | null) => {
                                        setFieldValue('category', value);
                                    }}
                                    renderInput={(params) => (
                                        <>
                                            <TextField
                                                error={!!errors.category}
                                                label={i18n.t('common.category')}
                                                variant={'filled'}
                                                {...params}
                                                name="type"
                                            />
                                        </>
                                    )}
                                />
                                <div className={classes.flexRowSpaceBetween}>
                                    <TextField
                                        style={{ width: '47%' }}
                                        fullWidth={true}
                                        variant={'filled'}
                                        label={i18n.t('common.amount')}
                                        name={'amount'}
                                        value={values.amount}
                                        error={!!errors.amount}
                                        type="tel"
                                        onChange={(e) => setFieldValue('amount', e.target.value)}
                                        InputProps={{
                                            inputProps: { min: 0 },
                                            endAdornment: (
                                                <InputAdornment position={'end'}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <div className={classes.divider} />
                                                        <Autocomplete
                                                            style={{
                                                                width: '50px',
                                                                marginTop: '28px',
                                                            }}
                                                            disableClearable
                                                            options={weightTypes}
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionSelected={(option, value) =>
                                                                option.id === value.id
                                                            }
                                                            value={values.unitOfMeasure}
                                                            onChange={(
                                                                e: object,
                                                                value: any | null
                                                            ) => {
                                                                setFieldValue(
                                                                    'unitOfMeasure',
                                                                    value
                                                                );
                                                            }}
                                                            renderInput={(params) => (
                                                                <>
                                                                    <TextField
                                                                        {...params}
                                                                        name="type"
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            disableUnderline:
                                                                                !errors.unitOfMeasure,
                                                                        }}
                                                                        error={
                                                                            !!errors.unitOfMeasure
                                                                        }
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Input
                                        style={{ width: '47%' }}
                                        error={errors.price}
                                        variant="filled"
                                        fullWidth={true}
                                        name={'price'}
                                        type="tel"
                                        label={i18n.t('common.price')}
                                        InputProps={{
                                            inputProps: { min: 0 },
                                        }}
                                    />
                                </div>
                                <Autocomplete
                                    freeSolo
                                    options={allergens}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    value={values.allergens}
                                    classes={{
                                        option: classes.dropdownItem,
                                        listbox: classes.listBox,
                                        paper: classes.paper,
                                    }}
                                    renderOption={(option) => {
                                        const exists = values.allergens.find(
                                            (currentOption: Allergen) =>
                                                currentOption.id === option.id
                                        );
                                        return (
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    let newValues;
                                                    if (exists) {
                                                        newValues = values.allergens.filter(
                                                            (selectedOption: Allergen) =>
                                                                selectedOption.id !== option.id
                                                        );
                                                    } else {
                                                        newValues = [...values.allergens, option];
                                                    }
                                                    setFieldValue('allergens', newValues);
                                                }}
                                                className={`${classes.flexRowSpaceBetween} ${
                                                    classes.width100
                                                } ${classes.bottomLine} ${
                                                    exists ? classes.selectedDropdownItem : ''
                                                }`}
                                            >
                                                <div className={classes.flexRow}>
                                                    <Checkbox color="primary" checked={!!exists} />
                                                    <span>{option.name}</span>
                                                </div>
                                                <img
                                                    src={option.icon}
                                                    alt={option.name}
                                                    style={{ marginRight: 10 }}
                                                />
                                            </div>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <>
                                            <TextField
                                                {...params}
                                                name="type"
                                                label={
                                                    values.allergens.length === 0
                                                        ? i18n.t('form.chooseAllergen')
                                                        : getSelectedAllergensList(values.allergens)
                                                }
                                                variant="filled"
                                            />
                                        </>
                                    )}
                                />
                                <div className={classes.buttonContainer}>
                                    <Button
                                        style={{ marginRight: 10 }}
                                        variant="text"
                                        color="primary"
                                        onClick={props.onClose}
                                    >
                                        {i18n.t('button.close')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={submitForm}
                                        disabled={!dirty}
                                    >
                                        {i18n.t(
                                            `${
                                                props.defaultValues
                                                    ? 'common.update'
                                                    : 'common.create'
                                            }`
                                        )}
                                    </Button>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}
