import { Action, Reducer } from 'redux';

import { RestaurantPaymentDetailsModel, TaxResponseModel } from 'api/models';
import { RoleData } from '../rolesStore/reducer';
import { RestaurantUserData } from '../usersStore/reducer';
import { RestaurantDetailsAction } from './actions';

export interface RestaurantDetailsState {
    restaurant: RestaurantDetails;
    restaurantInformation: RestaurantDetails;
    cuisineTypes: CuisineData[];
    taxes: TaxResponseModel;
    qrCodes: QRData[];
    qrCodesLoading: boolean;
    responsibleUsers: ResponsibleUser[];
}

export interface CuisineData {
    id: string;
    name: string;
    inUse: number;
}

export interface RestaurantContactPerson {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    isEnabled: boolean;
    restaurants: RestaurantUserData[];
    roles: RoleData[];
}

export interface RestaurantDetails {
    id: string;
    name: string;
    addressLine: string;
    zip: string;
    city: string;
    phoneNumber: string;
    email: string;
    website: string;
    latitude: number;
    longitude: number;
    contactPersons: RestaurantContactPerson[];
    logoUrl?: string;
    photoUrl?: string;
    hasHomeDelivery: boolean;
    hasTakeAway: boolean;
    hasTakeOut: boolean;
    hasOnSite: boolean;
    percentageForVorort?: number;
    percentageForTakeAway?: number;
    percentageForHomeDelivery?: number;
    paymentDetails: PaymentDetails;
    timeZone: string;
    openHours?: any[];
    qrCodesName: string;
}

export interface QRData {
    id?: string;
    name: string;
    service: string;
    url: string;
    area: string;
    areaId?: string;
}

export interface ResponsibleUser {
    id: string;
    position: Position;
    name: string;
}

export interface PaymentDetails extends RestaurantPaymentDetailsModel {}

//ACTIONS

//ACTION CREATORS

//REDUCER
const initialState: RestaurantDetailsState = {
    restaurant: {
        id: '',
        name: '',
        addressLine: '',
        zip: '',
        city: '',
        phoneNumber: '',
        email: '',
        website: '',
        latitude: 0,
        longitude: 0,
        contactPersons: [],
        hasHomeDelivery: false,
        hasTakeAway: false,
        hasTakeOut: false,
        hasOnSite: false,
        paymentDetails: {
            firmName: '',
            firstName: '',
            lastName: '',
            address: '',
            zip: '',
            city: '',
            accountNumber: '',
            iban: '',
            vat: '',
            tax: 0,
            currency: '',
            thanksMessage: '',
        },
        timeZone: '',
        qrCodesName: '',
    },
    restaurantInformation: {
        name: '',
        id: '',
        addressLine: '',
        zip: '',
        city: '',
        phoneNumber: '',
        email: '',
        website: '',
        latitude: 0,
        longitude: 0,
        contactPersons: [],
        hasHomeDelivery: false,
        hasTakeAway: false,
        hasTakeOut: false,
        hasOnSite: false,
        paymentDetails: {
            firmName: '',
            firstName: '',
            lastName: '',
            address: '',
            zip: '',
            city: '',
            accountNumber: '',
            iban: '',
            vat: '',
            tax: 0,
            currency: '',
            thanksMessage: '',
        },
        timeZone: '',
        qrCodesName: '',
    },
    cuisineTypes: [],
    taxes: {
        id: '',
        standardRate: 0,
        reducedRate: 0,
        specialRateForAccommodation: 0,
    },
    qrCodes: [],
    qrCodesLoading: true,
    responsibleUsers: [],
};

export const reducer: Reducer<RestaurantDetailsState> = (
    state: RestaurantDetailsState | undefined,
    incomingAction: Action
): RestaurantDetailsState => {
    if (state === undefined) {
        return initialState;
    }
    const action = incomingAction as RestaurantDetailsAction;

    switch (action.type) {
        case 'RESTAURANT_DETAILS_GET_SUCCESS':
            return {
                ...state,
                restaurant: action.restaurant,
            };
        case 'RESTAURANT_DETAILS_UPDATE_SUCCESS':
            return {
                ...state,
                restaurant: action.restaurant,
            };
        case 'GET_RESTAURANT_INFORMATION_SUCCESS': {
            return {
                ...state,
                restaurantInformation: action.restaurantInformation,
            };
        }
        case 'GET_CUISINE_TYPES_SUCCESS': {
            return {
                ...state,
                cuisineTypes: action.data,
            };
        }
        case 'UPDATE_RESTAURANT_INFORMATION_SUCCESS': {
            return {
                ...state,
                restaurantInformation: action.restaurantInformation,
            };
        }
        case 'GET_QR_CODES_SUCCESS': {
            return {
                ...state,
                qrCodes: action.data,
                qrCodesLoading: false,
            };
        }
        case 'DELETE_QR_CODE_SUCCESS': {
            //@ts-ignore
            const newQrCodes = state.qrCodes.filter((qrCode) => !action.data.includes(qrCode.id));
            return {
                ...state,
                qrCodes: newQrCodes,
            };
        }
        case 'UPDATE_QR_CODE_SUCCESS': {
            const restOfQrs = state.qrCodes.filter((qrCode) => qrCode.id !== action.data.id);
            return {
                ...state,
                qrCodes: [action.data, ...restOfQrs],
            };
        }
        case 'UPDATE_CONTACT_PERSONS': {
            return {
                ...state,
                restaurantInformation: {
                    ...state.restaurantInformation,
                    contactPersons: state.restaurantInformation.contactPersons.map((x) =>
                        action.users.find((y) => y.id === x.id)
                            ? action.users.find((y) => y.id === x.id)!
                            : x
                    ),
                },
            };
        }
        case 'UPDATE_PAYMENT_DETAILS_SUCCESS': {
            return {
                ...state,
                restaurantInformation: {
                    ...state.restaurantInformation,
                    paymentDetails: action.data,
                },
            };
        }
        case 'UPDATE_QR_CODES_NAME_SUCCESS': {
            return {
                ...state,
                restaurantInformation: { ...state.restaurantInformation, qrCodesName: action.data },
            };
        }
        case 'GET_RESTAURANT_RESPONSIBLE_USERS_SUCCESS': {
            return {
                ...state,
                responsibleUsers: action.data,
            };
        }
        case 'GET_TAXES_SUCCESS': {
            return {
                ...state,
                taxes: action.data,
            };
        }
        default:
            return state;
    }
};
