import React, { useImperativeHandle, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import i18n from 'i18n-js';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { alpha, Theme } from '@material-ui/core';
import imageCompression from 'browser-image-compression';

import commonStyles from 'config/commonStyles';
import { useRef, useState } from 'react';
import agent from 'api/agent';
import globalStore from 'store/globalStore';
import { ApplicationState } from 'store';
import { Positions } from 'constants/enums';
import { images } from 'assets';
import { theme } from 'config/theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        photoContainer: {
            display: 'flex',
            height: '158px',
            marginBottom: '25px',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            border: `1px solid ${theme.palette.grey['300']}`,
            borderRadius: '4px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
        },
        changePhotoButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: alpha(theme.palette.common.white, 0.8),
            color: theme.palette.primary.main,
            width: '45%',
            height: '36px',
            borderRadius: '4px',
            fontWeight: '500',
            letterSpacing: '1.25px',
            cursor: 'pointer',
        },
        removePhotoButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: alpha(theme.palette.common.white, 0.8),
            color: theme.palette.error.light,
            width: '45%',
            height: '36px',
            borderRadius: '4px',
            fontWeight: '500',
            letterSpacing: '1.25px',
            cursor: 'pointer',
        },
        addPhotoText: {
            fontSize: '14px',
            fontWeight: '500',
            letterSpacing: '1.25px',
            lineHeight: '16px',
            color: theme.palette.primary.main,
            marginTop: 20,
        },
        uploadPhotoContainer: {
            height: 43,
            width: 43,
            backgroundColor: theme.palette.background.default,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 4,
            cursor: 'pointer',
            margin: '0 auto',
        },
        removePhotoContainer: {
            height: 43,
            width: 43,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 4,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            cursor: 'pointer',
        },
        ...commonStyles,
    })
);

export interface ImageContainerRefProps {
    uploadImage: () => Promise<string>;
}

interface Props {
    photo: any;
    onChange?: (selected: boolean, photo: string) => void;
    disabled?: boolean;
    styles?: {};
    text?: string;
    required?: boolean;
}

const ImageContainer = React.forwardRef<ImageContainerRefProps, Props>(
    ({ photo, onChange, disabled, styles, text, required }, ref) => {
        const user = useSelector((state: ApplicationState) => state.user);
        const currentRestaurant = user.restaurants?.filter(
            (x) => x.id === user.currentRestaurantId
        )[0];

        const dispatch = useDispatch();
        const classes = useStyles();
        const [selectedFile, setSelectedFile] = useState<File | null>(null);
        const [fileUrl, setFileUrl] = useState(photo);
        const inputRef = useRef<HTMLInputElement>(null);

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        };

        function clickOnFileInput() {
            if (inputRef.current !== null) {
                inputRef.current!.click();
            }
        }

        function isDisabledByRoleOrPosition() {
            const currentPosition = currentRestaurant?.currentPosition;
            return currentPosition === Positions.COOK || currentPosition === Positions.WAITER;
        }

        useEffect(() => setFileUrl(photo), [photo]);

        useImperativeHandle(ref, () => ({
            uploadImage() {
                return new Promise<string>((resolve, reject) => {
                    const formData = new FormData();
                    if (selectedFile) {
                        imageCompression(selectedFile, options)
                            .then((result) => {
                                formData.append(
                                    'file',
                                    new File([result], 'image.jpeg', {
                                        type: result.type,
                                    })
                                );
                                agent.requests
                                    .post(`/File/uploadimage`, formData)
                                    .then((response) => {
                                        resolve(response);
                                    })
                                    .catch((error) => reject(error));
                            })
                            .catch((error) => reject(error));
                    } else if (fileUrl) {
                        resolve(fileUrl);
                    } else {
                        resolve('');
                    }
                });
            },
        }));

        function changeHandler(event: any) {
            const file = event.target.files[0];
            const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
            if (file && !acceptedImageTypes.includes(file['type'])) {
                return;
            }
            const size = parseFloat((file.size / (1024 * 1024)).toFixed(2));
            if (size > 10) {
                dispatch(
                    globalStore.actionCreators.showToaster(
                        'warning',
                        i18n.t('warnings.imageSizeWarning', {
                            number: 10,
                        })
                    )
                );
                return;
            }
            setSelectedFile(file);
            let reader = new FileReader();
            reader.onload = (e) => {
                setFileUrl(e.target!.result!.toString());
            };
            onChange && onChange(true, file.name);
            reader.readAsDataURL(file);
        }

        function removePhoto() {
            setSelectedFile(null);
            setFileUrl('');
            onChange && onChange(false, '');
        }

        return (
            <div
                onClick={!fileUrl && !disabled ? clickOnFileInput : () => ''}
                style={{
                    ...styles,
                    backgroundImage: `url(${fileUrl})`,
                    cursor: fileUrl ? 'auto' : 'pointer',
                    borderColor:
                        required && !selectedFile
                            ? theme.palette.error.main
                            : theme.palette.grey['300'],
                }}
                className={classes.photoContainer}
            >
                {fileUrl ? (
                    !isDisabledByRoleOrPosition() && (
                        <div className={classes.removePhotoContainer} onClick={removePhoto}>
                            <img src={images.icons.trash} alt="trash" />
                        </div>
                    )
                ) : (
                    <div>
                        <div className={classes.uploadPhotoContainer}>
                            <img src={images.icons.circlePlusColored} alt="plus" />
                        </div>
                        {text && <div className={classes.addPhotoText}>{text}</div>}
                    </div>
                )}
                <input
                    accept="image/*"
                    ref={inputRef}
                    type="file"
                    name="file"
                    id="upload"
                    onChange={changeHandler}
                    style={{ display: 'none' }}
                />
            </div>
        );
    }
);

export default ImageContainer;
