export const regexHelper = {
    /* eslint-disable */
    phoneNumber: /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
    //alternative - /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    onlyLetters: /^[\p{L}\s]+$/u,
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
    capitalCharacters: /([A-Z])/g,
    zip: /^[1-9]\d{3}$/, // Swiss Post Code Regex - https://rgxdb.com/r/3GYNWXVR
    city: /^[a-zA-Z\u0080-\u024F\s\/\-\)\(\`\.\"\']+$/,
    lettersAndNumbers: /^[a-zA-Z0-9_.-]*$/,
    lettersAndNumbersOnly: /^[a-zA-Z0-9]*$/,
    bankAccount: /^[0-9]{9,18}$/,
    atLeastOneNumberAndOtherCharacters: /.*[0-9].*/,
    twoDigisAfterComma: /^\d+(\.\d{0,2})?$/,
    removeAllTags: /(<([^>]+)>)/gi,
    /* eslint-enable */
};
