import React, { useRef } from 'react';

import i18n from 'i18n-js';
import * as yup from 'yup';

import {
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import { Formik, Form, FieldArray } from 'formik';
import { Input, ImageContainer } from 'components/shared';
import { ImageContainerRefProps } from 'components/shared/components/ImageContainer';

interface LabelManagementModalProps {
    title: string;
    buttonName: string;
    isOpen: boolean;
    onClose: () => void;
    onSave: (name: string, icon: string) => void;
    onRemove?: (id: string) => void;
    id?: string;
    name: string;
    icon: string;
}

export default function LabelManagementModal(props: LabelManagementModalProps) {
    const uploadRef = useRef<ImageContainerRefProps>(null);
    const initialValues = {
        name: props.name,
        icon: props.icon,
    };

    function removeIcon() {
        if (!!props.onRemove && !!props.id) {
            props.onRemove(props.id);
        }
    }

    const validationSchema = yup.object().shape({
        name: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Label Name',
            })
        ),
        icon: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Label Icon',
            })
        ),
    });

    return (
        <Dialog
            open={props.isOpen}
            aria-labelledby="form-dialog-title"
            style={{ backgroundColor: 'transparent' }}
        >
            <DialogTitle id="form-dialog-title">
                <Typography variant="h6">{props.title}</Typography>
                {props.id && (
                    <Button
                        type="button"
                        variant="contained"
                        style={{ backgroundColor: 'rgba(212, 83, 54, 1)', color: '#fff' }}
                        onClick={removeIcon}
                    >
                        {i18n.t('common.remove')}
                    </Button>
                )}
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {
                        const url = await uploadRef?.current?.uploadImage();
                        props.onSave(values.name, url || 'no url');
                    }}
                >
                    {({ values, setFieldValue, errors }) => (
                        <Form>
                            <ImageContainer
                                ref={uploadRef}
                                photo={values.icon}
                                required
                                onChange={(fileUrl) => {
                                    setFieldValue('icon', fileUrl);
                                }}
                            />
                            <Input
                                id="labl-name-input"
                                variant="filled"
                                fullWidth={true}
                                name="name"
                                type="text"
                                value={values.name}
                                placeholder="Label Name"
                                error={errors.name}
                            />
                            <DialogActions>
                                <Button type="button" color="primary" onClick={props.onClose}>
                                    {i18n.t('button.cancel')}
                                </Button>
                                <Button
                                    type="submit"
                                    //onClick={submitForm}
                                    variant="contained"
                                    color="primary"
                                >
                                    {props.buttonName}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}
