import i18n from 'i18n-js';

import { theme } from 'config/theme';
import { Roles, OrderServices, SpicinessLevels, Diets, DietaryLaws } from './enums';
import { images } from 'assets';

export interface MapObjectData {
    icon: string;
    name: string;
}

export const roleColor = new Map([
    [Roles.ADMIN as string, theme.palette.success.main],
    [Roles.OWNER as string, theme.palette.info.dark],
    [Roles.EMPLOYEE as string, theme.palette.info.main],
    [Roles.USER as string, theme.palette.warning.main],
]);

export const services = new Map<OrderServices, MapObjectData>([
    [
        OrderServices.OrderInRestaurant,
        {
            icon: images.icons.chartVorort,
            name: i18n.t('form.jamesVorort'),
        },
    ],
    [
        OrderServices.TakeAway,
        {
            icon: images.icons.chartTakeAway,
            name: i18n.t('form.jamesVorort'),
        },
    ],
    [
        OrderServices.HomeDelivery,
        {
            icon: images.icons.chartHomeDelivery,
            name: i18n.t('form.homeDelivery'),
        },
    ],
]);

export function getServices() {
    const t = i18n.t;
    return new Map<OrderServices, MapObjectData>([
        [
            OrderServices.OrderInRestaurant,
            {
                icon: images.icons.chartVorort,
                name: t('form.jamesVorort'),
            },
        ],
        [
            OrderServices.TakeAway,
            {
                icon: images.icons.chartTakeAway,
                name: t('form.takeAway'),
            },
        ],
        [
            OrderServices.HomeDelivery,
            {
                icon: images.icons.chartHomeDelivery,
                name: t('form.homeDelivery'),
            },
        ],
    ]);
}

export function getSpicinessLevels() {
    const t = i18n.t;
    return new Map<SpicinessLevels, MapObjectData>([
        [
            SpicinessLevels.None,
            {
                icon: '',
                name: t('form.notSpicy'),
            },
        ],
        [
            SpicinessLevels.MildlySpicy,
            {
                icon: images.icons.chili,
                name: t('form.mildlySpicy'),
            },
        ],
        [
            SpicinessLevels.MediumSpicy,
            {
                icon: images.icons.chili,
                name: t('form.mediumSpicy'),
            },
        ],
        [
            SpicinessLevels.FieryHot,
            {
                icon: images.icons.chili,
                name: t('form.fieryHot'),
            },
        ],
    ]);
}

export function getDiets() {
    const t = i18n.t;
    return new Map<Diets, MapObjectData>([
        [
            Diets.None,
            {
                icon: '',
                name: t('form.noDiet'),
            },
        ],
        [
            Diets.Vegan,
            {
                icon: images.icons.doubleLeaf,
                name: t('form.vegan'),
            },
        ],
        [
            Diets.Vegetarian,
            {
                icon: images.icons.leaf,
                name: t('form.vegetarian'),
            },
        ],
        [
            Diets.Bio,
            {
                icon: images.icons.bio,
                name: t('form.bio'),
            },
        ],
    ]);
}

export function getDietaryLaws() {
    const t = i18n.t;
    return new Map<DietaryLaws, MapObjectData>([
        [
            DietaryLaws.None,
            {
                icon: '',
                name: t('form.nodietaryLaw'),
            },
        ],
        [
            DietaryLaws.Halal,
            {
                icon: images.icons.halal,
                name: t('form.halal'),
            },
        ],
        [
            DietaryLaws.Kosher,
            {
                icon: images.icons.kosher,
                name: t('form.kosher'),
            },
        ],
    ]);
}
