import React from 'react';

import { useDispatch } from 'react-redux';
import i18n from 'i18n-js';
import { default as ordersStore } from 'store/ordersStore';

import { useAppSelector } from 'index';
import { useStyles } from '../styles';
import DishIngredientItem from './DishIngredientItem';

interface MenuIngredientManagementProps {
    forNewOrderItems?: boolean;
}

export default function MenuIngredientManagement(props: MenuIngredientManagementProps) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const itemsToAdd = useAppSelector((state) => state.orders.itemsToAdd);
    const orderDetails = useAppSelector((state) => state.orders.orderDetails);
    const selectedDishId = useAppSelector((state) => state.orders.selectedDishId);
    const selectedDailyMenuId = useAppSelector((state) => state.orders.selectedDailyMenuId);

    function getSelectedDish() {
        if (!!selectedDishId.length) {
            if (selectedDailyMenuId) {
                const dailyMenu = props.forNewOrderItems
                    ? itemsToAdd.find((each) => each.id === selectedDailyMenuId)
                    : orderDetails.orderItems.find((each) => each.id === selectedDailyMenuId);
                if (dailyMenu) {
                    return dailyMenu.dishes;
                }
            }

            const item = props.forNewOrderItems
                ? itemsToAdd.find((each) => each.id === selectedDishId)
                : orderDetails.orderItems.find((each) => each.id === selectedDishId);
            // Daily Menu
            if (item && item.dishes?.length) {
                return item.dishes;
            }
            const multipleItems = props.forNewOrderItems
                ? itemsToAdd.filter((each) => each.id === selectedDishId)
                : orderDetails.orderItems.filter((each) => each.id === selectedDishId);
            // Multi selection of same item
            if (multipleItems.length > 1) {
                return multipleItems;
            }
            return item;
        }
        return itemsToAdd[0];
    }

    function getDishData(id: string) {
        if (props.forNewOrderItems) {
            if (itemsToAdd[0]?.dishes?.length > 0 && selectedDailyMenuId) {
                return itemsToAdd[0].dishes.find((eachDish) => eachDish.id === id);
            }
            if (selectedDishId) {
                return itemsToAdd.find((eachDish) => eachDish.id === selectedDishId);
            }
            return itemsToAdd.find((eachDish) => eachDish.id === id);
        } else {
            if (orderDetails.orderItems[0]?.dishes?.length > 0 && selectedDailyMenuId) {
                return orderDetails.orderItems[0].dishes.find((eachDish) => eachDish.id === id);
            }
            if (selectedDishId) {
                return orderDetails.orderItems.find((eachDish) => eachDish.id === selectedDishId);
            }
            return orderDetails.orderItems.find((eachDish) => eachDish.id === id);
        }
    }

    function updateOrderDetails(newDish: any) {
        if (props.forNewOrderItems) {
            dispatch(ordersStore.actionCreators.addItemToEditedOrder(newDish, false, true));
        } else {
            const { uniqueId } = newDish;
            let index;
            if (selectedDailyMenuId) {
                orderDetails.orderItems.forEach((eachOrderItem) => {
                    index = eachOrderItem.dishes.findIndex((each) => each.id === newDish.id);
                    if (index > -1) {
                        eachOrderItem.dishes[index] = {
                            ...newDish,
                            modifiedIngredients: [...newDish.modifiedIngredients],
                        };
                        return;
                    }
                });
            } else {
                if (uniqueId) {
                    index = orderDetails.orderItems.findIndex(
                        (each) => each.uniqueId === newDish.uniqueId
                    );
                } else {
                    index = orderDetails.orderItems.findIndex((each) => each.id === selectedDishId);
                }
                if (index > -1) {
                    orderDetails.orderItems[index] = {
                        ...newDish,
                        modifiedIngredients: [...newDish.modifiedIngredients],
                    };
                    dispatch(ordersStore.actionCreators.setOrderDetails(orderDetails));
                }
            }
        }
    }

    function renderDishIngredientItems() {
        const dish = getSelectedDish();
        if (dish && Array.isArray(dish)) {
            return dish.map((each, i) => (
                <DishIngredientItem
                    // @ts-ignore
                    defaultDish={each}
                    open={i === 0}
                    hidable
                    onValueChange={updateOrderDetails}
                    key={`${i}-${each.id}`}
                />
            ));
        }
        return (
            <DishIngredientItem
                // @ts-ignore
                defaultDish={getDishData(dish.id)}
                onValueChange={updateOrderDetails}
                key={dish?.id}
                open
            />
        );
    }

    return (
        <div className={classes.menuIngredientManagementContainer}>
            {getSelectedDish() ? (
                renderDishIngredientItems()
            ) : (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                    }}
                >
                    <span className={classes.chooseDishText}>
                        {i18n.t('restaurant.chooseDishToManage')}
                    </span>
                </div>
            )}
        </div>
    );
}
