import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import i18n from 'i18n-js';

import MenuPreviewItem from './MenuPreviewItem';
import { default as menusStore } from 'store/menusStore';
import { default as ingredientsStore } from 'store/ingredientsStore';
import { ApplicationState } from 'store';
import { MenuCreateModel } from 'api/models';
import { MenuDetails } from 'components/menu/MenusPage/components';
import { images } from 'assets';
import { Roles, MenuType, Positions } from 'constants/enums';
import { useStyles } from '../styles';
import { MenuData } from 'store/menusStore/reducer';

interface MenusTableProps {
    menus: MenuData[];
    isModal?: boolean;
    close?: () => void;
    setMenuName?: (name: string) => void;
    handleDetails?: (id: string) => void;
    showMenuDetails?: boolean;
    setShowMenuDetails?: () => void;
    menuId?: string;
    separatorText?: string;
}
export default function MenusTable(props: MenusTableProps) {
    const classes = useStyles();
    const lang = useSelector((state: ApplicationState) => state.global.language);
    const ingredients = useSelector((state: ApplicationState) => state.ingredients);
    const user = useSelector((state: ApplicationState) => state.user);
    const currentRestaurant = user.restaurants?.find((x) => x.id === user.currentRestaurantId);
    const dispatch = useDispatch();
    const [renderMenus, setRenderMenus] = useState(
        currentRestaurant?.currentPosition === Positions.MANAGER
            ? [...props.menus]
            : props.menus.filter((menu) => menu.isEnabled)
    );

    const draggedMenu = useRef<MenuData | null>(null);
    const [draggedMenuId, setDraggedMenuId] = useState('');

    function onDragStart(menu: MenuData) {
        setDraggedMenuId(menu.id);
        draggedMenu.current = menu;
    }

    function onDragEnter(menu: MenuData) {
        if (menu.id !== draggedMenu?.current?.id) {
            const tempMenus = [...renderMenus];
            const removedIndex = tempMenus.indexOf(draggedMenu?.current!);
            if (removedIndex < 0) {
                return;
            }
            const removedMenu = tempMenus.splice(removedIndex, 1);
            const targetMenuIndex = tempMenus.indexOf(menu);
            if (removedIndex === targetMenuIndex) {
                if (removedIndex === 0 && targetMenuIndex === 0) {
                    tempMenus.splice(targetMenuIndex + 1, 0, removedMenu[0]);
                } else if (removedIndex === 1 && targetMenuIndex === 0) {
                    tempMenus.unshift(removedMenu[0]);
                } else {
                    tempMenus.splice(targetMenuIndex + 1, 0, removedMenu[0]);
                }
            } else if (removedIndex > targetMenuIndex) {
                tempMenus.splice(targetMenuIndex, 0, removedMenu[0]);
            } else {
                tempMenus.splice(targetMenuIndex + 1, 0, removedMenu[0]);
            }

            setRenderMenus(tempMenus);
        }
    }

    function onDragEnd() {
        if (
            props.menus.indexOf(draggedMenu.current!) !== renderMenus.indexOf(draggedMenu.current!)
        ) {
            dispatch(menusStore.actionCreators.reorderMenus(renderMenus.map((x) => x.id)));
        }
        setDraggedMenuId('');
        draggedMenu.current = null;
    }

    useEffect(() => {
        setRenderMenus(
            currentRestaurant?.currentPosition === Positions.MANAGER
                ? [...props.menus]
                : props.menus.filter((menu) => menu.isEnabled)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.menus]);
    useEffect(() => {
        dispatch(menusStore.actionCreators.getMenus());
        ingredients.ingredients.length === 0 &&
            dispatch(ingredientsStore.actionCreators.getIngredients(lang));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            {props.separatorText && !props.showMenuDetails && (
                <>
                    <div style={{ borderBottom: '1px solid #E5E5E5', height: 10 }}></div>
                    <div
                        style={{
                            paddingLeft: 22,
                            fontSize: 10,
                            marginTop: 10,
                            color: 'rgba(0, 0, 0, 0.87)',
                            letterSpacing: 1.5,
                        }}
                    >
                        {props.separatorText}
                    </div>
                </>
            )}
            <div
                className={classes.menusTableRoot}
                //className={clsx(classes.root, classes.forModal)}
                /* className={clsx(classes.root, {
                [classes.forModal]: props.isModal,
            })} */
            >
                {props.showMenuDetails ? (
                    <MenuDetails
                        id={props.menuId}
                        close={() => {
                            !!props.setShowMenuDetails && props.setShowMenuDetails();
                        }}
                        isModal={props.isModal}
                    />
                ) : (
                    <>
                        {user.role === Roles.OWNER &&
                            !!props.menus.find((x) => x.type !== MenuType.Special) && (
                                <MenuPreviewItem
                                    disable={(id: string) =>
                                        dispatch(menusStore.actionCreators.disableMenu(id))
                                    }
                                    key={'ING'}
                                    menuType={'ING'}
                                    edit={null}
                                    menu={{
                                        edit: '',
                                        id: 'ii',
                                        name: i18n.t('common.ingredients'),
                                        description: 'Some',
                                        price: 0,
                                        photo: images.ingredientsDefault,
                                        type: MenuType.Standard,
                                        sections: [''],
                                        dishesCount: ingredients.ingredientsCount,
                                        isEnabled: true,
                                        position: 0,
                                        availableFrom: 0,
                                        availableTo: 0,
                                        discount: 0,
                                    }}
                                    showDetails={props.isModal ? props.handleDetails : undefined}
                                    setName={props.setMenuName}
                                />
                            )}
                        {renderMenus.length > 0 &&
                            renderMenus.map((menu, i) => (
                                <div
                                    key={menu.id}
                                    draggable={user.role === Roles.OWNER}
                                    onDragStart={() => onDragStart(menu)}
                                    onDragEnter={() => onDragEnter(menu)}
                                    onDragEnd={onDragEnd}
                                    onDragOver={(e) => e.preventDefault()}
                                    style={{
                                        opacity: menu.id === draggedMenuId ? 0.1 : 1,
                                    }}
                                >
                                    <MenuPreviewItem
                                        disable={(id: string) =>
                                            dispatch(menusStore.actionCreators.disableMenu(id))
                                        }
                                        key={menu.id}
                                        menuType={''}
                                        edit={(model: MenuCreateModel, id: string) =>
                                            dispatch(menusStore.actionCreators.editMenu(model, id))
                                        }
                                        menu={menu}
                                        showDetails={
                                            props.isModal ? props.handleDetails : undefined
                                        }
                                        setName={props.setMenuName}
                                    />
                                </div>
                            ))}
                    </>
                )}
            </div>
        </>
    );
}
