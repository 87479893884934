import React, { useRef, useState, useMemo, useEffect } from 'react';

import { Dialog, DialogTitle, Button, DialogContent, makeStyles } from '@material-ui/core';
import JoditEditor from 'jodit-react';
import i18n from 'i18n-js';
import commonStyles from 'config/commonStyles';

const useStyles = makeStyles({
    ...commonStyles,
    dialog: {
        '& .MuiDialog-paper': {
            height: '100%',
        },
        '& .MuiDialogTitle-root h2': {
            textAlign: 'end',
        },
        '& .jodit-react-container': {
            height: '100%',
        },
    },
    editor: {
        height: '100% !important',
        '& .jodit-workplace': {
            height: 'calc(100% - 90px) !important',
        },
    },
});

interface TextEdiorProps {
    isOpen: boolean;
    text: string;
    onClose: () => void;
    onSave: (text: string) => void;
}

export default function TextEditor(props: TextEdiorProps) {
    const classes = useStyles();
    const editor = useRef(null);
    const [content, setContent] = useState(props.text);

    function save() {
        props.onSave(content);
        setContent('');
        props.onClose();
    }

    function cancel() {
        setContent(props.text);
        props.onClose();
    }

    const config = useMemo(
        () => ({
            readonly: false, // all options from https://xdsoft.net/jodit/docs/,
            placeholder: 'Start typings...',
            disablePlugins: [
                'table',
                'file',
                'image',
                'video',
                'speechRecognize',
                'spellcheck',
                'ai-commands',
                'ai-assistant',
                'fullsize',
                'preview',
                'print',
                'showall',
            ],
            removeButtons: ['dots', 'brush'],
            processPasteHTML: false,
            className: classes.editor,
            defaultActionOnPaste: 'insert_only_text' as const,
        }),
        []
    );

    useEffect(() => setContent(props.text), [props.text]);
    return (
        <Dialog
            maxWidth={'lg'}
            open={props.isOpen}
            aria-labelledby="form-dialog-title"
            className={classes.dialog}
            closeAfterTransition
        >
            <DialogTitle>
                <Button type="button" color="primary" onClick={cancel} className={classes.mRight10}>
                    {i18n.t('button.cancel')}
                </Button>
                <Button
                    disabled={props.text === content}
                    onClick={save}
                    variant="contained"
                    color="primary"
                >
                    {i18n.t('button.save')}
                </Button>
            </DialogTitle>
            <DialogContent>
                <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    onChange={(newContent) => {
                        setContent(newContent);
                    }} // preferred to use only onBlur this option to update the content for performance reasons
                />
            </DialogContent>
        </Dialog>
    );
}
