export enum Roles {
    ADMIN = 'Admin',
    OWNER = 'RestaurantOwner',
    USER = 'MobileUser',
    EMPLOYEE = 'RestaurantEmployee',
}

export enum MenuType {
    Daily,
    Standard,
    Special,
}

export enum OrderStatus {
    New,
    InProgress,
    Ready,
    Closed,
    Unpaid,
    Non,
    Cancel,
    OnDelivery,
    Delivered,
}

export enum Positions {
    WAITER = 'Waiter',
    BARMEN = 'Barmen',
    COOK = 'Cook',
    MANAGER = 'Manager',
}

export enum OrderDishStatus {
    None,
    InProgress,
    Ready,
    Canceled,
}

export enum PaymentMethod {
    None,
    Online,
    Card,
    Cash,
    PayLater,
}

export enum OrderUpdateStatus {
    UpdateReadyDishes,
    UpdateOrdersCount,
    ShowOverduedOrders,
}

export enum OrderServices {
    HomeDelivery = 'HomeDelivery',
    OrderInRestaurant = 'Vorort',
    TakeAway = 'TakeAway',
}

export enum TaxType {
    None = 'None',
    StandardRate = 'StandardRate',
    ReducedRate = 'ReducedRate',
    SpecialRateForAccommodation = 'SpecialRateForAccommodation',
}

export enum SpicinessLevels {
    None,
    MildlySpicy,
    MediumSpicy,
    FieryHot,
}

export enum Diets {
    None,
    Vegetarian,
    Vegan,
    Bio,
}

export enum DietaryLaws {
    None,
    Halal,
    Kosher,
}
