import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { FieldArray, Form, Formik } from 'formik';
import i18n from 'i18n-js';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {
    Typography,
    makeStyles,
    DialogActions,
    InputAdornment,
    IconButton,
} from '@material-ui/core';

import { CuisineData } from 'store/restaurantDetailsStore/reducer';
import agent from 'api/agent';
import { images } from 'assets';
import { Input } from 'components/shared';
import { theme } from 'config/theme';
import globalStore from 'store/globalStore';
import { helperFunctions } from 'helpers';
import { truncateString } from 'helpers/helperFunctions';
import { RestaurantTypesManagementModel, LabelModel } from 'api/models';
import LabelManagementModal from './LabelManagementModal';

interface LabelsModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const useStyles = makeStyles({
    input: {
        '& .MuiInputBase-input': {
            padding: '17px 12px 17px',
        },
        '& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-filled': {
            marginTop: 0,
            marginRight: 15,
        },
        '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)':
            {
                marginTop: 0,
                marginRight: 15,
            },
        '& .MuiInputAdornment-positionStart .MuiIconButton-label': {
            height: 32,
            width: 32,
            backgroundColor: theme.palette.common.white,
            borderRadius: 50,
        },
        '& .MuiInputAdornment-positionStart .MuiIconButton-label img': {
            maxHeight: 25,
            maxWidth: 25,
        },
    },
    errorMessage: {
        position: 'absolute',
        left: 10,
        top: 5,
        color: theme.palette.error.dark,
        fontSize: 12,
        zIndex: 1,
    },
    addButton: {
        width: 136,
        position: 'absolute',
        top: 15,
        right: 25,
    },
    removeCircle: {
        height: 20,
        width: 20,
    },
});

interface DishLabel extends LabelModel {
    id: string;
}

export function LabelsModal(props: LabelsModalProps) {
    const classes = useStyles();
    const [labels, setLabels] = useState<DishLabel[]>([]);
    const [showAdd, setShowAdd] = useState(false);
    const [labelToEdit, setLabelToEdit] = useState<DishLabel | null>(null);
    const dispatch = useDispatch();
    async function getLabels() {
        dispatch(globalStore.actionCreators.showSpiner());
        const data = await agent.Products.GetLabels();
        setLabels(data);
        dispatch(globalStore.actionCreators.hideSpiner());
    }

    async function createLabel(name: string, icon: string) {
        const model: LabelModel = {
            name,
            icon,
        };
        dispatch(globalStore.actionCreators.showSpiner());
        const label = await agent.Products.CreateLabel(model);
        setLabels([...labels, label]);
        dispatch(globalStore.actionCreators.hideSpiner());
        handleShowAdd();
    }

    async function editLabel(name: string, icon: string) {
        const model: LabelModel = {
            name,
            icon,
        };
        dispatch(globalStore.actionCreators.showSpiner());
        const updatedLabel = await agent.Products.EditLabel(labelToEdit?.id!, model);
        const updatedLabels = labels.map((label) =>
            label.id === updatedLabel.id ? updatedLabel : label
        );
        setLabels(updatedLabels);
        setLabelToEdit(null);
        dispatch(globalStore.actionCreators.hideSpiner());
    }

    async function removeLabel(id: string) {
        dispatch(globalStore.actionCreators.showSpiner());
        const updatedLabelId = await agent.Products.DeleteLabel(id);
        const updatedLabels = labels.filter((label) => label.id !== updatedLabelId);
        setLabels(updatedLabels);
        setLabelToEdit(null);
        dispatch(globalStore.actionCreators.hideSpiner());
    }

    function handleShowAdd() {
        setShowAdd(!showAdd);
    }

    useEffect(() => {
        getLabels();
    }, []);

    return (
        <div>
            <Dialog open={props.isOpen} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <Typography variant="h6">{i18n.t('common.labels')}</Typography>
                </DialogTitle>
                <DialogContent>
                    {!!!labels?.length && (
                        <DialogContentText>{i18n.t('main.nothingToSee')}</DialogContentText>
                    )}
                    <Formik
                        initialValues={{ labels: labels }}
                        enableReinitialize
                        onSubmit={() => {}}
                    >
                        {({ values, submitForm }) => (
                            <Form>
                                <FieldArray name="cuisines">
                                    {() => (
                                        <div>
                                            {!!labels?.length &&
                                                labels.map((label, index) => (
                                                    <Input
                                                        className={classes.input}
                                                        id={label.id}
                                                        key={label.name}
                                                        variant="filled"
                                                        fullWidth={true}
                                                        name={`cuisines.${index}.name`}
                                                        type="text"
                                                        value={truncateString(label.name, 50)}
                                                        placeholder={label.name}
                                                        readOnly
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="pen"
                                                                        onClick={() =>
                                                                            setLabelToEdit(label)
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={images.icons.pen}
                                                                            alt="edit-icon"
                                                                            className={
                                                                                'disabled-img'
                                                                            }
                                                                        />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <IconButton aria-label="pen">
                                                                        <img
                                                                            src={label.icon}
                                                                            alt="edit-icon"
                                                                            className={
                                                                                'disabled-img'
                                                                            }
                                                                        />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                ))}
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleShowAdd}
                                                className={classes.addButton}
                                            >
                                                {helperFunctions.truncateLangString(
                                                    i18n.t('button.addNew'),
                                                    12
                                                )}
                                            </Button>
                                        </div>
                                    )}
                                </FieldArray>
                                <DialogActions>
                                    <Button type="button" color="primary" onClick={props.onClose}>
                                        {i18n.t('button.close')}
                                    </Button>
                                    {/* <Button
                                        onClick={submitForm}
                                        variant="contained"
                                        color="primary"
                                        disabled={!formHasChanged}
                                    >
                                        {i18n.t('common.save')}
                                    </Button> */}
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
            <LabelManagementModal
                name=""
                icon=""
                buttonName={i18n.t('button.create')}
                title={i18n.t('common.addNewLabel')}
                isOpen={showAdd}
                onClose={handleShowAdd}
                onSave={createLabel}
            />
            <LabelManagementModal
                name={labelToEdit?.name || ''}
                icon={labelToEdit?.icon || ''}
                id={labelToEdit?.id || ''}
                buttonName={i18n.t('button.save')}
                title={i18n.t('common.edit')}
                isOpen={!!labelToEdit}
                onClose={() => setLabelToEdit(null)}
                onSave={editLabel}
                onRemove={removeLabel}
            />
        </div>
    );
}
