import agent, { prepareHeaders } from 'api/agent';
import {
    IngredientModel,
    UpdateIngredientModel,
    UpdateCategoryModel,
    LabelModel,
} from 'api/models';

export const Products = {
    GetIngredients: (lang: 'en' | 'de', page: number, limit: number, getAll: boolean) =>
        agent.requests.get('/Product/ingredient', {
            params: {
                lang,
                page,
                limit,
                getAll,
            },
        }),
    CreateIngredient: (model: IngredientModel) =>
        agent.requests.put('/Product/ingredient', model, prepareHeaders()),
    UpdateIngredient: (model: UpdateIngredientModel) =>
        agent.requests.post(`/Product/ingredient/?id=${model.id}&lang=${model.lang}`, model),
    GetCategories: (lang: 'en' | 'de', page: number, limit: number) =>
        agent.requests.get('/Product/ingredient-category', {
            params: {
                lang,
                page,
                limit,
            },
        }),
    CreateCategory: (name: string) =>
        agent.requests.put(
            '/Product/ingredient-category',
            {
                name,
            },
            prepareHeaders()
        ),
    UpdateCategory: (model: UpdateCategoryModel) =>
        agent.requests.post(
            `/Product/ingredient-category/?id=${model.id}&lang=${model.lang}`,
            model
        ),
    DeleteCategory: (id: string) => agent.requests.delete(`/Product/ingredient-category/?id=${id}`),
    DisableEnable: (id: string) =>
        agent.requests.post(`/Product/ingredient-category/disable-enable/?id=${id}`),
    GetAllergiens: (lang: string = 'de') => agent.requests.get(`/Product/allergen?lang=${lang}`),
    CreateLabel: (model: LabelModel) =>
        agent.requests.put('/Product/label', model, prepareHeaders()),
    EditLabel: (id: string, model: LabelModel) =>
        agent.requests.post(`/Product/label?id=${id}`, model),
    DeleteLabel: (id: string) => agent.requests.delete(`/Product/label?id=${id}`),
    GetLabels: () => agent.requests.get('/Product/label'),
};
